.charts__performance{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.5rem;
    padding: 1rem;
    background-color: var(--secondary-color);
    box-shadow: 0 4px 8px var(--secondary-color);
    border-radius: 0.5rem;
}

.charts__performance-title{
    font-size: var(--fs-small);
    font-weight: var(--fw-bold);
    color: var(--white-gray-color);
    text-transform: uppercase;
}

.charts__performance-box{
    width: 100%;
    position: relative;
    max-height: 400px;
}