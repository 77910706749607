.total__staff{
    position: relative;
    padding: 0.85rem;
    background-color: var(--secondary-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px var(--secondary-color);
}

.total__staff-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.total__staff-heading_item{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.1rem;
}

.total__staff-count_total{
    color: var(--primary-color);
    
}
.total__staff-title{
    color: var(--white-gray-color);
    font-size: var(--fs-small);
    text-transform: uppercase;
}

.total__staff-count_total{
    font-size: var(--fs-normal);
    font-weight: 700;
}

.total__staff-count_dep{
    font-size: var(--fs-small);
    color: var(--gray-color);
    
}

.total__staff-content_chart,
.total__staff-content_values{
    display: flex;
    align-items: center;
}
.total__staff-content_chart{
    margin-top: 1rem;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
}

.total__staff-content_values{
    gap: 3rem;
}

.total__staff-content_value{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.total__staff-content_value-name{
    position: relative;
    display: flex;
    align-items: center;
    font-size: var(--fs-small);
    color: var(--gray-color);
    font-weight: 600;
}

.total__staff-content_value-name span{
    content: '';
    position: absolute;
    top: 0.2rem;
    left: -1rem;
    width: 0.6rem;
    height: 0.6rem;
    background-color: rgb(255, 99, 132);
    border-radius: 50%;
}

.total__staff-content_value:nth-child(1) .total__staff-content_value-name span{
    background-color: rgb(54, 162, 235);
}

.total__staff-content_count{
    font-size: var(--fs-small);
    color: var(--white-gray-color);
    font-weight: 500;
}