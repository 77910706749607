.home__container{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.home__cards{
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 0.5rem;
}

.home__card{
    padding: 0.85rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.65rem;
    background-color: var(--secondary-color);
    border-radius: 8px;
    box-shadow: 0 0px 10px 2px var(--secondary-color);
}

.home__card-span{
    padding: 0.5rem;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    border-radius: 8px;
}

.home__card-icon{
    font-size: var(--fs-large);
    color: var(--bg-color);
}

.home__card-item{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
}

.home__card-title{
    color: var(--white-gray-color);
    font-size: var(--fs-small);
    text-transform: uppercase;
}

.home__card-value{
    font-size: var(--fs-smaller);
    color: var(--primary-color);
    font-weight: var(--fw-bold);
}

.home__charts{
    position: relative;
    width: 100%;
}

.home__items-grid{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 0.65rem;
    overflow: hidden;
}