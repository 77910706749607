.location__table-data{
    /* max-width: inherit; */
    position: relative;
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow {
    background-color: var(--secondary-color) !important;
    color: var(--white-gray-color) !important;
  }
  
  .css-1j4lznq-MuiDataGrid-root .MuiDataGrid-cell,
  .css-rtrcn9-MuiTablePagination-root,
  .css-zylse7-MuiButtonBase-root-MuiIconButton-root,
  .css-1g8djar-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell{
    color: var(--white-gray-color) !important;
  }
  
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, 
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
    color: var(--primary-color) !important;
  }