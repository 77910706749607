.sidebar{
    position: fixed;
    width: 250px;
    height: 100vh;
    top: 0;
    left: 0;
    animation: sidebar .3s linear;
    transition: .3s linear;
    z-index: 1000;
}

@keyframes sidebar {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

.sidebar.active{
    position: absolute;
    left: -100%;
}

.sidebar__container{
    width: 100%;
    position: relative;
    background-color: var(--secondary-color);
    box-shadow: 0 4px 8px var(--secondary-color);
    min-height: 100vh;
}

.sidebar_div{
    padding: 1rem;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    overflow-y: auto;
    gap: 0.85rem;
}

.sidebar__container::-webkit-scrollbar{
    width: 5px;
    background-color: var(--bg-color);
}

.sidebar__container::-webkit-scrollbar-button{
    background-color: var(--primary-color);
    border-radius: 5px;
}

.sidebar__content{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.sidebar__profile{
    padding: 0.6rem;
    width: 100%;
    display: flex;
    align-items: center;
    /* flex-direction: column; */
    gap: 0.5rem;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
}

.sidebar__profile-img{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 1px solid var(--primary-color);
    padding: 0.2rem;
    box-shadow: 0 4px 8px var(--bg-color);
}

.sidebar__profile-items{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.sidebar__profile-name{
    font-size: var(--fs-small);
    color: var(--white-gray-color);
}

.sidebar__profile-role{
    font-size: var(--fs-smaller);
    color: var(--gray-color);
    font-weight: var(--fw-bold);
}

.sidebar__items{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.5rem;
}

.sidebar__items-title{
    font-size: var(--fs-small);
    color: var(--white-gray-color);
    font-weight: var(--fw-bold);
    text-transform: uppercase;
}

.sidebar__list{
    width: 100%;
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.sidebar__link{
    width: 100%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: var(--fs-small);
    color: var(--gray-color);
    transition: all .3s ease-in-out;
}

.sidebar__link.button{
    background-color: transparent;
    justify-content: space-between;
}

.sidebar__link.active{
    background-color: var(--primary-color);
    border-radius: 10px;
    color: var(--bg-color);
}

.sidebar__link:hover{
    color: var(--primary-color);
}

.sidebar__link span{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.sidebar__icon{
    font-size: inherit;
    transition: all .3s ease-in-out;
}

.sidebar__list-sub{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.sidebar__list-ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 1rem;
    max-height: 0px;
    margin-bottom: 0.5rem;
    margin-top: -0.5rem;
    opacity: 0;
    visibility: hidden;
    transition: .4s ease-in-out;
    background-color: var(--bg-color);
    animation: subsidebar .4s linear;
    /* display: none; */
}

@keyframes subsidebar {
    from{
        max-height: 0px;
    }to{
        max-height: 400px;
    }
}

.sidebar__list-ul.active {
    max-height: 400px;
    opacity: 1;
    visibility: visible;
    margin-top: 0.5rem;
}


@media screen and (max-width: 992px) {
    .sidebar{
        width: 100%;
        background-color: rgba(0,0,0,0.1);
        z-index: 10001;
    }

    .sidebar__container{
        width: 250px;
    }
}