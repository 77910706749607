.action__cell{
    position: relative;
    text-align: center;
}

.action__cell-icon{
    font-size: 1rem;
    color: var(--white-gray-color);
    margin-left: 0.5rem;
    cursor: pointer;
}

.action__cell-btn{
    padding: 0.3rem 1rem;
    background-color: transparent;
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
}

.action__cell-span{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.4rem;
    width: 100px;
    color: #fff;
    font-size: var(--fs-smaller);
    border-radius: 30px;
    font-weight: bold;
}

.action__cell-span.approved{
    background-color: rgb(0, 133, 29);
}

.action__cell-span.pending{
    background-color: rgb(247,201,0);
}

.action__cell-span.rejected{
    background-color: rgb(245,46,46);
}

.action__cell-span__icon.approve{
    color: rgb(96, 255, 96);
}

.action__cell-span__icon.pending{
    color: rgb(255, 255, 114);
}

.action__cell-span__icon.reject{
    color: rgb(255, 91, 91);
}

/* FOR LEAVE REQUEST STATUS */
.action__cell-buttons{
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.action__cell-button{
    padding: 0.5rem;
    width: 100px;
    border-radius: 7px;
    font-size: var(--fs-smaller);
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.action__cell-button:hover{
    opacity: 0.8;
}

.action__cell-button.approve{
    background-color: var(--primary-color);
    color: var(--bg-color);
}

.action__cell-button.pending{
    background-color: rgb(247,201,0);
}

.action__cell-button.reject{
    background-color: rgb(245,46,46);
}

.action__cell-button.request_active{
    cursor: not-allowed;
}