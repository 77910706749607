.location__form{
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 1;
    position: relative;
}

.location__form-heading{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.location__form-title{
    font-size: var(--fs-small);
    color: var(--white-gray-color);
}

.location__form-desc{
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
}

.location__form-content{
    display: grid;
    grid-template-columns: 1fr 400px;
    gap: 1rem;
    padding: 1rem;
}

.location__form-form{
    position: relative;
    width: 100%;
}

.location__form-group{
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 0.5rem;
}

.location__form-items{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.2rem;
}

.location__form-label{
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
    text-transform: uppercase;
}

.location__form-input{
    width: 100%;
    padding: 0.5rem;
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
    border: 2px solid var(--bg-color);
    border-radius: 5px;
    background: transparent;
}

.location__form-input:focus{
    border: 2px solid var(--primary-color);
}

.location__form-btn{
    align-self: flex-end;
    margin-top: 2rem;
}

.custom-marker-icon{
    width: 4rem;
}

.location__form-table{
    position: relative;
    /* width: 100%; */
}

@media screen and (max-width: 998px){
    .location__form-content{
        grid-template-columns: 1fr;
    }

    .location__form-group{
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}