.absent__card{
    padding: 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1.5rem;
    background-color: var(--secondary-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px var(--secondary-color);
}

.absent__card-header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.absent__card-title{
    font-size: var(--fs-small);
    color: var(--white-gray-color);
    text-transform: uppercase;
}

.absent__card-count{
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--fs-smaller);
    font-weight: var(--fw-bold);
    color: var(--bg-color);
    background-color: var(--primary-color);
    border-radius: 5px;
}

.absent__card-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.85rem;
}

.absent__card-items{
    width: 100%;
    display: flex;
    align-items: start;
    gap: 0.5rem;
}

.absent__card-img{
    width: 3rem;
    height: 3rem;
    padding: 0.1rem;
    border: 1px solid var(--primary-color);
    border-radius: 50%;
}

.absent__card-div{
    flex: 1;
    margin-top: 0.2rem;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    align-items: start;
}

.absent__card-name{
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
}

.absent__card-role{
    font-size: var(--fs-tiny);
    color: var(--gray-color);
}

.absent__card-date{
    font-size: var(--fs-tiny);
    color: var(--primary-color);
}