.employees__container{
    position: relative;
    margin-top: 1.5rem;
}

.employees__cards{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
}

.employees__card{
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--secondary-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px var(--secondary-color);
}

.employees__card-item{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.employees__card-title{
    font-size: var(--fs-small);
    color: var(--white-gray-color);
    
}

.employees__card-count{
    font-size: var(--fs-normal);
    color: var(--primary-color);
}

.employees__items{
    position: relative;
    margin-top: 1rem;
    padding: 1rem;
    background-color: var(--secondary-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px var(--secondary-color);
}

.employees__search{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.employees__search-title{
    font-size: var(--fs-small);
    color: var(--white-gray-color);
    text-transform: uppercase;
}

.employees__search-box{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.employees__search-span{
    font-size: var(--fs-small);
    color: var(--white-gray-color);
    font-weight: 600;
}

.employees__search-input{
    padding: 0.4rem;
    font-size: var(--fs-smaller);
    width: 100%;
    color: var(--white-gray-color);
    background-color: transparent;
    border: 1px solid var(--primary-color);
    outline: none;
    border-radius: 5px;
}

.employees__table{
    margin-top: 1rem;
    position: relative;
    width: 100%;
    overflow-x: auto !important;
}

.employees__table-profile{
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.employees__table-img{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}

.employees__table-profile_list{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.employees__table-profile_name{
    font-size: var(--fs-smallelr);
    color: var(--white-gray-color);
}

.employees__table-profile_email{
    font-size: var(--fs-smaller);
    color: var(--gray-color);
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow {
    background-color: var(--secondary-color) !important;
    color: var(--white-gray-color) !important;
  }
  
  .css-1j4lznq-MuiDataGrid-root .MuiDataGrid-cell,
  .css-rtrcn9-MuiTablePagination-root,
  .css-zylse7-MuiButtonBase-root-MuiIconButton-root,
  .css-1g8djar-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell{
    color: var(--white-gray-color) !important;
  }
  
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, 
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
    color: var(--primary-color) !important;
  }


@media screen and (max-width: 768px) {
    .employees__search-box{
        width: 100%;
    }
    .employees__search-title{
        display: none;
    }
}