.auth{
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.auth__content{
    width: 400px;
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    background-color: var(--secondary-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px var(--primary-color);
}

.auth__icon{
    font-size: 3.5rem;
    color: var(--primary-color);
}

.auth__title{
    font-size: var(--fs-normal);
    color: var(--white-gray-color);
    font-weight: var(--fw-bold);
    text-transform: uppercase;
}

.auth__subtitle{
    color: var(--white-gray-color);
    font-size: var(--fs-smaller);
    text-align: center;
}

.auth__form{
    width: 100%;
    position: relative;
    margin-top: 2rem;
}

.auth__list{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.85rem;
}

.auth__items{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.3rem;
}

.auth__label{
    font-size: var(--fs-smaller);
    font-weight: var(--fw-bold);
    color: var(--gray-color);
    text-transform: uppercase;
}

.auth__input{
    width: 100%;
    padding: 0.7rem 0.6rem 0.7rem 2.5rem;
    background-color: transparent;
    border: 1px solid var(--primary-color);
    border-radius: 30px;
    color: var(--white-gray-color);
}

.auth__form-span{
    position: absolute;
    top: 1.33rem;
    left: 0;
    padding: 0.5rem 0.6rem;
    background-color: var(--primary-color);
    border-radius: 50%;
}

.auth__items-icon{
    font-size: var(--fs-normal);
}

.auth__form-toggle{
    font-size: var(--fs-normal);
    color: var(--gray-color);
    position: absolute;
    top: 2rem;
    right: 0.5rem;
    cursor: pointer;
}

.auth__error{
    font-size: var(--fs-tiny);
    color: rgb(206, 0, 0);
}

.auth__btn{
    align-self: flex-end;
    min-width: 100px;
}

.auth__link{
    font-size: var(--fs-smaller);
    color: var(--primary-color);
}