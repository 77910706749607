.payment__table{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
    padding: 1rem;
    background-color: var(--secondary-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px var(--secondary-color);
}

.payment__table-header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.payment__table-title{
    font-size: var(--fs-small);
    color: var(--white-gray-color);
    text-transform: uppercase;
}

.payment__table-link{
    font-size: var(--fs-smaller);
    color: rgb(236, 0, 0);
}

.payment__table-container{
    position: relative;
}

.payment__table-container::-webkit-scrollbar{
    background-color: transparent;
    width: 5px;
    height: 3px;
}

.payment__table-container::-webkit-scrollbar-thumb{
    border-radius: 2px;
    background-color: var(--primary-color);
    cursor: pointer;
}

.payment__table-status{
    display: inline-flex;
    align-items: center;
    gap: 0.2rem;
    padding: 0.4rem;
    font-size: 0.8rem;
    font-weight: var(--fw-bold);
    border-radius: 30px;
}

.payment__table-status.approved{
    border: 1px solid var(--green-color);
    color: var(--green-color);
}

.payment__table-status.pending{
    border: 1px solid var(--yellow-color);
    color: var(--yellow-color);
}

.payment__table-status.declined{
    border: 1px solid var(--orange-color);
    color: var(--orange-color);
}


.payment__table-status_icon{
    font-size: var(--fs-small);
}

.payment__table-div{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.payment__table-btn{
    padding: 0.5rem;
    width: 80px;
    font-size: var(--fs-smaller);
    font-weight: var(--fw-bold);
    border-radius: 5px;
    transition: all .3s ease-in-out;
}

.payment__table-btn.approve:hover{
    background-color: transparent;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.payment__table-btn.approve{
    background-color: var(--primary-color);
    color: var(--bg-color);
    border: 1px solid var(--primary-color);
}

.payment__table-btn.decline{
    background-color: var(--orange-color);
    color: var(--bg-color);
    border: 1px solid var(--orange-color);
}

.payment__table-btn.decline:hover{
    background-color: transparent;
    color: var(--orange-color);
    border: 1px solid var(--orange-color);
}

@media Screen and (max-width: 768px){
    .payment__table-header{
        flex-direction: column;
        justify-content: center;
    }
}