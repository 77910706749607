.location__table{
    /* width: 100%; */
    position: relative;
    padding: 0.5rem;
    border: 1px solid var(--bg-color);
}

.location__table-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.location__table-title{
    font-size: var(--fs-small);
    color: var(--white-gray-color);
    text-transform: uppercase;
}

.location__table-search{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.location__search-span{
    font-size: var(--fs--smaller);
    color: var(--white-gray-color);
}

.location__search-input{
    padding: 0.5rem;
    font-size: var(--fs--smaller);
    color: var(--white-gray-color);
    border: 1px solid var(--primary-color);
    border-radius: 7px;
    background: transparent;
}

.location__table-status{
    position: relative;
}

.location__table-status_btn{
    display: flex;
    padding: 0.2rem 0.5rem;
    align-items: center;
    gap: 0.2rem;
    font-size: var(--fs--smaller);
    color: var(--white-gray-color);
    background: var(--secondary-color);
    border: 1px solid var(--primary-color);
    border-radius: 30px;
}

.location__table-status_icon{
    font-weight: bold;
    font-size: var(--fs-small);
}

.location__table-status_icon.open{
    color: rgb(0, 168, 0);
}

.location__table-status_icon.close{
    color: rgb(255, 10, 10);
}

.location__table-popover{
    background: transparent;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    color: var(--white-gray-color);
}

.location__table-popover:disabled{
    color: var(--gray-color);
}

.location__table-data{
    /* width: 100%; */
    position: relative;
}