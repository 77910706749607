.request__status{
    position: relative;
}

.request__status-container{
    padding: 0.55rem;
    margin-top: 2rem;
    position: relative;
    /* width: 100%; */
}

.request__status-title{
    font-size: var(--fs-small);
    text-transform: uppercase;
}

.request__status-content{
    padding: 1rem 1rem;
    background-color: var(--secondary-color);
    border-radius: 8px;
}

.request__status-heading{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.request__status-search{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
}

.request__status-search span{
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
}

.request__status-input{
    background-color: transparent;
    padding: 0.5rem;
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
    border: 1px solid var(--bg-color);
    border-radius: 8px;
}

.request__status-input:hover{
    border: 1px solid var(--primary-color);
}

.request__status-table{
    position: relative;
    margin-top: 1rem;
    width: 100%;
    /* overflow-x: auto !important; */
}

.request__status-table_btn{
    padding: 0.3rem;
    font-size: var(--fs-smaller);
    color: var(--primary-color);
    cursor: pointer;
    border: none;
    background: none;

}

.request__status-table_span{
    padding: 0.3rem 0.5rem;
    text-align: center;
    font-size: var(--fs-smaller);
    min-width: 90px;
}

.request__status-table_span.pending{
    background-color: rgba(255, 255, 0, 0.24);
    color: rgb(230, 230, 0);
}

.request__status-table_span.approved{
    background-color: var(--primary-color);
    color: var(--primary-color);
}

.request__status-table_span.rejected{
    background-color: rgba(228, 0, 0, 0.356);
    color: rgb(228, 0, 0);
}

.request__status-profile{
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.request__status-img{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}

.request__status-profile_list{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.request__status-profile_name{
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
}

.request__status-profile_email{
    font-size: var(--fs-smaller);
    color: var(--gray-color);
}

.action__cell{
    position: relative;
    text-align: center;
}

.action__cell-icon{
    font-size: 1rem;
    color: var(--white-gray-color);
    margin-left: 0.5rem;
    cursor: pointer;
}

.action__cell-btn{
    padding: 0.3rem 1rem;
    background-color: transparent;
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
}

.css-1wg9lti-MuiDataGrid-root{
    color: var(--white-gray-color) !important;
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow {
    background-color: var(--secondary-color) !important;
    color: var(--white-gray-color) !important;
  }
  
  .css-1j4lznq-MuiDataGrid-root .MuiDataGrid-cell,
  .css-rtrcn9-MuiTablePagination-root,
  .css-zylse7-MuiButtonBase-root-MuiIconButton-root,
  .css-1g8djar-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell{
    color: var(--white-gray-color) !important;
  }
  
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, 
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
    color: var(--primary-color) !important;
  }