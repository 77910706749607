.news__card{
    position: relative;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background-color: var(--secondary-color);
    box-shadow: 0 4px 10px rgba(0,0,0,0.05);
    border-radius: 7px;
    overflow: hidden;
}

.news__card-top,
.news__card-profile{
    display: flex;
    align-items: start;
}

.news__card-top{
    justify-content: space-between;
}

.news__card-profile{
    gap: 0.5rem;
}

.news__card-profile_img{
    width: 3rem;
    height: 3rem;
    border: 2px solid #fff;
    border-radius: 50%;
    -webkit-box-shadow: 0 5px 25px 0 rgba(0,0,0,0.2);
    -moz-box-shadow: 0 5px 25px 0 rgba(0,0,0,0.2);
    -ms-box-shadow: 0 5px 25px 0 rgba(0,0,0,0.2);
    box-shadow: 0 5px 25px 0 rgba(0,0,0,0.2);
}

.news__card-profile_detail{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.news__card-profile_name{
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
    line-height: 1;
}

.news__card-profile_role,
.news__card-profile_date{
    font-size: 0.65rem;
    color: var(--white-gray-color);
}

.news__card-icon{
    font-size: var(--fs-normal);
    color: var(--white-gray-color);
    font-weight: 600;
    
    cursor: pointer;
}

.news__card-content{
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
    margin-top: 0.75rem;
    overflow-wrap: break-word;
    white-space: pre-line;
}

.news__card-btn{
    background-color: transparent;
    color: var(--white-gray-color);
    margin-left: 0.4rem;
    font-size: var(--fs-smaller);
    color: var(--primary-color);
}

.news__card-bottom{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.5rem;
}

.news__card-bottom_item{
    display: flex;
    align-items: center;
}

.news__card-bottom_button{
    background-color: transparent;
    font-size: var(--fs-small);
    
}

.news__card-bottom_item:first-child .news__card-bottom_button{
    color: rgb(233, 3, 3);
}
.news__card-bottom_item:last-child .news__card-bottom_button{
    color: var(--primary-color);
}

.news__card-bottom_count{
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
    
}

.news__card-more{
    position: absolute;
    right: 1rem;
    top: 2.5rem;
    width: max-content;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.3rem;
    background-color: var(--primary-color);
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.05);
    z-index: 200;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
}

.news__card-more.active{
    opacity: 1;
    visibility: visible;
}

.news__card-more_button{
    padding: 0.2rem 0;
    background-color: transparent;
    color: var(--white-gray-color);
    font-size: var(--fs-smaller);
    
    transition: all .3s ease-in-out forwards;
}

.news__card-more_button:hover{
    color: var(--bg-color);
}

.news__card-more_overlay{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.005);
    opacity: 0;
    visibility: hidden;
}

.news__card-more_overlay.active{
    opacity: 1;
    visibility: visible;
}

.news__card-comment{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 0px;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: .5s ease-in-out;
}

.news__card-comment.active{
    max-height: 500px;
    opacity: 1;
    visibility: visible;
}

/* COMMENT */

.comment{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.comment__box{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.comment__box-input{
    width: 100%;
    padding: 0.4rem;
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
    background-color: transparent;
    border: 1px solid var(--gray-color);
    outline: none;
    border-radius: 5px;
}

.comment__box-button{
    min-width: 50px !important;
    padding: 0.5rem !important;
}

.comment__box-button.active{
    cursor: not-allowed;
    opacity: 0.8;
}

.comment__box-icon{
    color: inherit;
    animation: role 1s infinite steps(8);
    font-size: var(--fs-normal);
}

@keyframes role {
    100% {transform: rotate(1turn)}
}

.comment__card{
    width: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    padding-bottom: 0.3rem;
    border-bottom: 1px solid var(--gray-color);
}

.comment__card-heading,
.comment__card-profile{
    display: flex;
    align-items: start;
}

.comment__card-heading{
    width: 100%;
    justify-content: space-between;
}

.comment__card-profile{
    gap: 0.3rem;
}

.comment__card-img{
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
}

.comment__card-detail{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.1rem;
}

.comment__card-name{
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
    text-transform: capitalize;
}

.comment__card-role,
.comment__card-date{
    font-size: var(--fs-tiny);
    color: var(--gray-color);
}

.comment__card-desc{
    padding: 0 0.5rem;
    padding-left: 2rem;
    font-size: var(--fs-tiny);
    color: var(--white-gray-color);
    white-space: pre-line;
}

@media screen and (max-width: 768px) {
    .comment__card-desc{
        padding-left: 0.65rem;
    }
    
}