.admin__settings{
    position: relative;
}

.admin__settings__container{
    margin-top: 1rem;
    padding: 0.85rem;
}

.admin__settings__content{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
}

.admin__settings__tabs,
.admin__settings__tab-items{
    width: 100%;
    padding: 0.85rem;
    background: var(--secondary-color);
    border-radius: 8px;
    box-shadow: 0 4px 10px var(--secondary-color);
}
.admin__settings__tabs{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.admin__settings__tab{
    padding: 0.2rem;
    /* min-width: 150px; */
    position: relative;
    font-size: var(--fs-smaller);
    text-transform: uppercase;
    color: var(--primary-color);
    /* border: 1px solid var(--primary-color); */
    cursor: pointer;
    background: transparent;
    transition: all .3s ease-in-out;
}

.admin__settings__tab::before{
    content: '';
    position: absolute;
    bottom: -0.7rem;
    left: 0;
    width: 0;
    height: 0.2rem;
    background-color: var(--primary-color);
    border-radius: 5px;
    transition: all .3s ease-in-out;
}

.admin__settings__tab.active::before{
    width: 100%;
}

.admin__settings__tab.active{
    font-weight: var(--fw-bold);
}

.admin__settings__tab:hover{
    opacity: 0.8;
}


@media screen and (max-width: 768px){
    .admin__settings__tabs{
        flex-direction: column;
        align-items: start;
    }
}

@media screen and (max-width: 572px){
    .admin__settings__tab{
        width: 100%;
        border: 2px solid var(--bg-color);
    }

    .admin__settings__tab::before{
        display: none;
    }

    .admin__settings__tab.active{
        background-color: var(--primary-color);
        color: var(--bg-color);
    }
}