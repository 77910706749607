
.profile__form{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.5rem;
}

.profile__form-title{
    margin: 0.5rem 0;
    font-size: var(--fs-normal);
    color: var(--white-gray-color);
    text-transform: uppercase;
}

.profile__form-list{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    gap: 0.85rem;
}

.profile__form-item{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.3rem;
}

.profile__form-label{
    display: flex;
    align-items: center;
    gap: 0.3rem;
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
    font-weight: var(--fw-bold);
    text-transform: uppercase;
}

.profile__form-label span{
    font-size: var(--fs-tiny);
    color: var(--gray-color);
}

.profile__form-input{
    width: 100%;
    padding: 0.5rem;
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid var(--gray-color);
    transition: all .3s;
}

.profile__form-input:focus{
    border: 1px solid var(--primary-color);
}

.profile__form-error{
    font-size: 0.75rem;
    color: rgb(228, 0, 0);
}

.profile__form-btn{
    margin-top: 1rem;
    align-self: flex-end;
}

.profile__form-img{
    width: 6.5rem;
    height: 6.5rem;
    border-radius: 50%;
}

.profile__form-label_profile{
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 100%;
    align-items: center;
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
    border: 1px solid var(--gray-color);
    border-radius: 8px;
    cursor: pointer;
}

.profile__form-label_input{
    display: none;
}

.profile__form-label_icon{
    color: inherit;
    font-size: var(--font-small);
}