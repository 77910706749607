.chat__container{
    margin-top: 1.5rem;
    position: relative;
    display: grid;
    grid-template-columns: 300px 1fr;
    border: 1px solid var(--primary-color);
}

.chat__container-left{
    display: flex;
    flex-direction: column;
    background-color: var(--secondary-color);
    border-right: 1px solid var(--primary-color);
}

.chat__container-search{
    margin: 1rem 1rem;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    overflow: hidden;
}

.chat__container-search .bx{
    padding: 0.5rem;
    font-size: var(--h2-font-size);
    color: var(--title-color);
    background-color: var(--primary-color);
}

.chat__search-input{
    width: 100%;
    padding: 0.5rem;
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
    background: transparent;
    border: none;
    outline: none;
}

.chat__container-left_content{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    min-height: calc(100vh - 13.5rem);
    height: calc(100vh - 13.5rem);
    overflow-y: auto;
}

.chat__container-left_profile{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    transition: .3s;
    cursor: pointer;
}

.chat__container-left_profile:hover{
    background-color: var(--primary-color);
}

.chat__container-left_img,
.chatbox__profile-img{
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    border: 2px solid #fff;
    border-radius: 50%;
    -webkit-box-shadow: 0 5px 25px 0 rgba(0,0,0,0.2);
    -moz-box-shadow: 0 5px 25px 0 rgba(0,0,0,0.2);
    -ms-box-shadow: 0 5px 25px 0 rgba(0,0,0,0.2);
    box-shadow: 0 5px 25px 0 var(--secondary-color);
}

.chat__container-left_user{
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.chat__container-left_name,
.chatbox__profile-name,
.chat__container-left_status,
.chatbox__profile-status{
    color: var(--white-gray-color);
    
}

.chat__container-left_name,
.chatbox__profile-name{
    font-size: var(--fs-smaller);
}

.chat__container-left_status,
.chatbox__profile-status{
    font-size: var(--fs-smaller);
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 0.65rem;
}

.chat__container-left_status:before,
.chatbox__profile-status:before{
    position: absolute;
    content: '';
    top: 0.3rem;
    left: -0.65rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
}

.chat__container-left_status.online:before,
.chatbox__profile-status.online:before{
    background-color: green;
}

.chat__container-left_status.offline:before,
.chatbox__profile-status.offline:before{
    background-color: gray;
}

.chat__container-left_count{
    padding: 0.01rem 0.3rem;
    position: absolute;
    top: 1.5rem;
    right: 0.5rem;
    /* width: 0.83rem;
    height: 0.83rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: end;
    font-size: var(--fs-tiny);
    font-weight: bold;
    color: #fff;
    background-color: rgb(0, 202, 0);
    border-radius: 50%;
}

.chat__container-right{
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 8rem);
}

.chat__container-right_heading{
    width: 100%;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-color);
}

.chat__container-right_title{
    color: var(--white-gray-color);
    font-size: var(--fs-normal);
    /*  */
}


/* CHAT BOX */

.chatbox{
    position: relative;
    width: 100%;
    height: calc(100vh - 8rem);
    display: flex;
    flex-direction: column;
}

.chatbox__heading{
    padding: 0.65rem 1.25rem;
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: space-between;
    background-color: var(--secondary-color);
    border-bottom: 1px solid var(--primary-color);
}

.chatbox__profile{
    display: flex;
    align-items: start;
    gap: 0.5rem;
}

.chatbox__profile-user{
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.chatbox__content{
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 0 !important;
    height: 600px;
}

.chatbox__content-item{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 4rem);
    margin-bottom: 4rem !important;
    overflow-y: scroll;
}

.chatbox__content-chats{
    position: relative;
    width: 80%;
    padding: 0.5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    transition: all .3s linear;
}

.chatbox__content-chats.right{
    align-self: flex-end;
    align-items: end;
    justify-content: end;
}

.chatbox__content-chats.left{
    align-self: flex-start;
    align-items: start;
    justify-content: start;
}

.chatbox__content-chats.right .chatbox__content-message{
    background-color: var(--primary-color);
    color: #fff;
}

.chatbox__content-chats.left .chatbox__content-message{
    background-color: var(--secondary-color);
    color: var(--white-gray-color);
}

.chatbox__content-date{
    font-size: 0.65rem;
    color: var(--primary-color);
}

.chatbox__content-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chatbox__content-message{
    position: relative;
    padding: 0.5rem;
    border-radius: 6px;
    margin-top: 0.5rem;
}

.chatbox__content-message p{
    font-size: var(--fs-smaller);
    line-height: 1;
}

.arrow-down {
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}

.chatbox__content-chats.right .arrow-down{
    border-top: 8px solid var(--primary-color);
}

.chatbox__content-chats.left .arrow-down{
    border-top: 8px solid var(--secondary-color);
}

.chatbox__content-field{
    position: absolute;
    bottom: 0 !important;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--secondary-color);
}

.chatbox__content-list{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    padding: 0 0.2rem;
}

.chatbox__input{
    /* width: 600px !important; */
    width: calc(100% - 80px);
}

.chatbox__file-output{
    position: relative;
    width: 100%;
    height: 300px;
}

.chatbox__file-btn{
    position: absolute;
    padding: 0.3rem;
    top: 1rem;
    right: 0.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: red;
    color: #fff;
    font-size: var(--fs-smaller);
    border-radius: 50%;
    z-index: 1000;
}

.chatbox__file-output_img{
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chatbox__label{
    background: var(--primary-color);
    padding: 0.5rem;
    color: var(--bg-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.chatbox__file{
    display: none;
}

.chatbox__submit-btn{
    position: relative;
    padding: 0.5rem 0;
    min-width: 80px;
}


@media screen and (max-width: 992px) {
    .chat__container{
        grid-template-columns: 250px 1fr;
    }
}

@media screen and (max-width: 550px) {
    .chat__container{
        grid-template-columns: 1fr;
        gap: 0.5rem;
    }
}