.news__stand-heading{
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.news__stand-title{
    font-size: var(--fs-normal);
    color: var(--white-gray-color);
    line-height: 1;
}

.news__stand-subtitle{
    font-size: var(--fs-smaller);
    color: var(--gray-color);
}

.news__stand-editor{
    background-color: transparent;
    padding: 0 0.5rem;
    color: var(--white-gray-color) !important;
}

.news__stand-body{
    margin-top: 1rem;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 1rem;
    padding: 0 0.85rem;
}

.news__stand-box{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-height: 100vh;
}

.news__stand-box_form{
    padding: 0.85rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--secondary-color);
}

.news__stand-box__form-button{
    max-width: 100px;
    align-self: flex-end;
}

.news__stand-list{
    position: sticky !important;
    right: 0;
    top: 5rem;
    height: 400px;
}

.news__stand-item{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.news__stand-item_title{
    color: var(--white-gray-color);
    font-size: var(--fs-normal);
    font-weight: 600;
    
}

    /*........BREAKPOINTS.......*/
  /* For large devices */
@media screen and (max-width: 992px) {
    .news__stand-body{
        grid-template-columns: 1fr;
    }

    .news__stand-box{
        gap: 0.1rem;
        min-height: auto;
    }

    .news__stand-list{
        order: -1;
        position: relative;
        height: auto;
        top: 0;
    }
}

  /* For medium devices */
@media screen and (max-width: 768px) {
    
}