@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:wght@400;500;600;700;800;900&family=Roboto:wght@400;500;700;900&display=swap');


:root{
  /* COLORS */

  /* --bg-color: rgb(241,242,247); */
  --bg-color: rgb(34,45,50);
  /* --primary-color: rgb(255, 255, 255); */
  --primary-color: rgb(36,223,179);
  --secondary-color: rgb(26,34,38);
  /* --gray-color: rgb(53,60,72); */
  --gray-color: rgb(89, 101, 109);
  --black-color: rgb(0,0,0);
  --white-gray-color: rgb(221, 221, 221);
  --white-color: rgb(255, 255, 255);
  --green-color: rgb(16, 194, 22);
  --yellow-color: rgb(247,201,0);
  --orange-color: rgb(249,99,50);
  --blue-color: rgb(58,113,202);
  --topbar-color: rgb(53,60,72);

  /* FONT SIZES */

  --fs-larger: 2.5rem;
  --fs-extra-large: 2.2rem;
  --fs-large: 2rem;
  --fs-medium: 1.5rem;
  --fs-normal: 1.2rem;
  --fs-small: 1rem;
  --fs-smaller: 0.85rem;
  --fs-tiny: 0.7rem;

  /*.......FONT WEIGHT.......*/
  --fw-normal: 400;
  --fw-medium: 500;
  --fw-semi-bold: 600;
  --fw-bold: 700;
  --fw-extra-bold: 800;
  --fw-super-bold: 900;
}

[data-theme="dark-mode"]{
  --bg-color: rgb(25,24,26);
  --primary-color: rgb(33,32,34);
  --gray-color: rgb(53,60,72);
  --black-color: rgb(0,0,0);
  --white-color: rgb(252, 248, 248);
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

body{
  background-color: var(--bg-color);
}

html{
  scroll-behavior: smooth;
}

*::-webkit-scrollbar{
  background-color: transparent;
  width: 5px;
  height: 4px;
}

*::-webkit-scrollbar-thumb{
  border-radius: 5px;
  background-color: var(--primary-color);
}

html::-webkit-scrollbar{
  background-color: transparent;
  width: 5px;
}

html::-webkit-scrollbar-thumb{
  border-radius: 5px;
  background-color: var(--primary-color);
}

h1,h2,h3{
  color: var(--gray-color);
  font-weight: var(--fw-bold);
}

ul{
  list-style: none;
}

a{
  text-decoration: none;
}

button{
  cursor: pointer;
  outline: none;
  border: none;
}

img{
  max-width: 100%;
  max-height: 100%;
}

input,
textarea{
  outline: none;
}

section{
  position: relative;
  width: 100%;
  padding: 0.5rem 1rem;
  margin-top: 4.5rem;
}

.btn{
  padding: 0.5rem;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  border-radius: 5px;
  font-size: var(--fs-smaller);
  font-weight: var(--fw-semi-bold);
  text-transform: uppercase;
  background-color: transparent;
  transition: all .3s ease-in-out;
}

.btn:hover{
  background-color: var(--primary-color);
  color: var(--bg-color);
}

.layout{
  position: relative;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.layout__container{
  position: relative;
  width: calc(100% - 250px);
  margin-left: 250px;
  transition: all .3s ease-in-out;
}

.layout__container.active{
  width: 100%;
  margin-left: 0;
}

.heading{
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin: 0.5rem;
}

.heading__box{
  display: flex;
  flex-direction: column;
  align-items: start;
}

.heading-item{
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.heading-title{
  font-size: var(--fs-normal);
  color: var(--white-gray-color);
  
}

.heading__title-icon{
  color: var(--white-gray-color);
  font-size: var(--fs-normal);
  
}

.heading-icon{
  font-size: var(--fs-normal);
  color: gray;
}

.heading-text{
  display: flex;
  align-items: center;
  font-size: var(--fs-smaller);
  color: var(--gray-color);
  display: flex;
  align-items: center;
  gap:  0.2rem;
}

.heading-subtitle{
  font-size: var(--fs-smaller);
  color: var(--gray-color);
}

/* ADD DEPARTMENT MODAL */
.modal{
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  animation-name: modal .4s linear .3s;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.1);
}

.modal-overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  
}

.modal-container{
  padding: 3rem 0;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100vh;
  overflow-y: auto;
}

.modal-content{
  position: relative;
  width: 600px;
  margin: 2rem 1rem;
  padding: 1rem;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: var(--secondary-color);
  transform: translate(-50%, -50%);
  border-radius: 8px;
}

.modal-content.overflow{
  margin: auto;
  top: 0;
  left: 0;
  transform: none !important;
}

.modal-heading{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-title{
  font-size: var(--fs-small);
  color: var(--white-gray-color);
  
}

.modal-btn{
  padding: 0 5px;
  font-size: var(--fs-small);
  color: #fff;
  background-color: rgb(204, 0, 0);
  border-radius: 5px;
}

.modal-form{
  position: relative;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.modal-items{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  /* flex-direction: column; */
  gap: 0.5rem;
}

.modal-field{
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 0.1rem;
}

.modal-label{
  font-size: var(--fs-smaller);
  color: var(--white-gray-color);
  font-weight: 600;
}

.modal-label span,
.modal-error,
.error__message{
  color: rgb(204, 0, 0);
  font-size: var(--fs-smaller);
}

.modal-input{
  width: 100%;
  padding: 0.5rem;
  height: 40px;
  background-color: transparent;
  border: 2px solid var(--bg-color);
  border-radius: 5px;
  outline: none;
  font-size: var(--fs-smaller);
  color: var(--white-gray-color);
  transition: .3s;
}

.modal-input:focus{
  border: 1px solid var(--primary-color);
}

.modal-button{
  margin-top: 0.5rem;
  align-self: flex-end;
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow {
  background-color: var(--secondary-color) !important;
  color: var(--white-gray-color) !important;
}

.css-1j4lznq-MuiDataGrid-root .MuiDataGrid-cell,
.css-rtrcn9-MuiTablePagination-root,
.css-zylse7-MuiButtonBase-root-MuiIconButton-root,
.css-1g8djar-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell{
  color: var(--white-gray-color) !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, 
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
  color: var(--primary-color) !important;
}

.css-fpb3bp .MuiDataGrid-row{
  color: #fff !important;
}

@media screen and (max-width: 992px) {
  .layout{
    grid-template-columns: 1fr;
  }

  .layout__container{
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  :root{
    /* FONT SIZES */

    --fs-larger: 1.8rem;
    --fs-large: 1.6rem;
    --fs-medium: 1.2rem;
    --fs-normal: 1rem;
    --fs-small: 0.85rem;
    --fs-smaller: 0.75rem;
    --fs-tiny: 0.7rem;
  }
}

@media screen and (max-width: 576px) {
  
}

@media screen and (max-width: 350px) {
  
}