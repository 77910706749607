/* ....message.... */
.message{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    z-index: 10000;
    display: flex;
    justify-content: flex-end;
    transition: all .3s ease-in-out;
}

.message.active{
    opacity: 1;
    visibility: visible;
}

.message__overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: none;
}

.message__box{
    margin-top: 4rem;
    margin-right: 6rem;
    width: 300px;
    height: max-content;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--secondary-color);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: all .3s linear;
    animation-name: message;
    animation-duration: 0.5s;
}

@keyframes message {
    from{
        top: -100%;
    }
    to{
        top: 0;
    }
}

.message__top,
.message__bottom{
    padding: 0.4rem 0.5rem;
    width: 100%;
    display: flex;
    align-items: center;
}

.message__top{
    justify-content: space-between;
    border-bottom: 1px solid var(--bg-color);
}

/* .message__title,
.message__count{
    
} */

.message__title{
    font-size: var(--fs-small);
    color: var(--white-gray-color);
}

.message__btn{
    color: rgb(238, 0, 0);
    font-size: var(--fs-smaller);
    background: none;
}

.message__list{
    position: relative;
    padding: 0.85rem;
    max-height: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.65rem;
    overflow-y: auto;
}

.message__items{
    width: 100%;
    display: flex;
    align-items: start;
    gap: 0.2rem;
    border-bottom: 1px solid var(--bg-color); 
    cursor: pointer;
}

.message__items:last-child{
    border-bottom: none;
}

.message__div{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.message__img{
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
}

.message__name{
    font-size: var(--fs-smaller);
    color: var(--title-color);
    
}

.message__text{
    font-size: var(--fs-smaller);
    color: var(--gray-color);
    width: 230px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.message__bottom{
    justify-content: center;
    border-top: 1px solid var(--bg-color);
}

.message__bottom-link{
    font-size: var(--fs-smaller);
    text-align: center;
    color: var(--white-gray-color);
}

@media screen and (max-width: 550px){
    .message__box{
        margin-right: 1rem;
    }
}