.loader__box{
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 0.85rem;
}
.loader {
    position: relative;
    margin-top: 0.8rem;
    width: 3rem;
    height: 3rem;
    border-top: 3px solid var(--primary-color);
    opacity: 0.8;
    border-radius: 50%;
    color: #766DF4;
    animation: s7 1s infinite steps(8);
}

@keyframes s7 {
    100% {transform: rotate(1turn)}
}