.topbar{
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - 250px);
    height: 4rem;
    display: flex;
    align-items: center;
    background-color: var(--secondary-color);
    box-shadow: 0 2px 4px var(--secondary-color);
    z-index: 1000;
}

.topbar.active{
    width: 100%;
    left: 0;
}

.topbar__container{
    position: relative;
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topbar__content{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.topbar__btn{
    position: relative;
    background-color: transparent;
    font-size: var(--fs-medium);
    color: var(--white-gray-color);
}

.topbar__span{
    position: absolute;
    top: -0.5rem;
    left: 0.5rem;
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--fs-tiny);
    background-color: rgb(216, 0, 0);
    border-radius: 50%;
}

.topbar__img{
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 50%;
    border: 1px solid var(--primary-color);
    cursor: pointer;
}


/* TOPBAR DROP-DOWN */
.topbar__dropdown{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100000;
}

.topbar__dropdown-content{
    position: absolute;
    top: 4rem;
    right: 1rem;
    padding: 1rem 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background-color: var(--secondary-color);
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    animation: dropdown .3s linear;
}

@keyframes dropdown {
    0%{
        top: 1rem;
        opacity: 0;
    }
    100%{
        top: 4rem;
        opacity: 1;
    }
}

.topbar__dropdown-link{
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
}



@media screen and (max-width: 992px) {
    .topbar{
        left: 0;
        width: 100%;
    }
}