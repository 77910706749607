/* ....NOTIFICATION.... */
.notification{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* opacity: 0;
    visibility: hidden; */
    z-index: 10000;
    display: flex;
    justify-content: flex-end;
    transition: all .3s ease-in-out;
}

/* .notification.active{
    opacity: 1;
    visibility: visible;
} */

.notification__overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: none;
}

.notification__box{
    margin-top: 4rem;
    margin-right: 8rem;
    width: 300px;
    height: max-content;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--secondary-color);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: all .3s linear;
    animation-name: notification;
    animation-duration: 0.5s;
}

@keyframes notification {
    from{
        top: -100%;
    }
    to{
        top: 0;
    }
}

.notification__top,
.notification__bottom{
    padding: 0.4rem 0.5rem;
    width: 100%;
    display: flex;
    align-items: center;
}

.notification__top{
    justify-content: space-between;
    border-bottom: 1px solid var(--bg-color);
}

.notification__title{
    font-size: var(--fs-small);
    color: var(--white-gray-color);
}

.notification__btn{
    color: rgb(238, 0, 0);
    font-size: var(--fs-smaller);
    background: none;
}

.notification__list{
    position: relative;
    padding: 0.85rem;
    max-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.65rem;
    overflow-y: scroll !important;
}

.notification__items{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.3rem;
    border-bottom: 1px solid var(--bg-color); 
}

.notification__items:last-child{
    border-bottom: none;
}

.notication__data{
    display: flex;
    align-items: center;
    gap: 0.4rem;
    cursor: pointer;
}

.notification__img{
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
}

.notification__text{
    font-size: var(--fs-smaller);
    color: var(--gray-color);
    
    width: 220px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.notification__date{
    font-size: var(--fs-smaller);
    color: var(--gray-color);
}

.notification__buttons{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.notification__button{
    font-size: var(--fs-smaller);
    background: none;
    border: none;
    color: var(--primary-color);
}

.notification__button:nth-child(1){
    color: rgb(223, 0, 0);
}

.notification__bottom{
    justify-content: center;
    border-top: 1px solid var(--bg-color);
}

.notification__bottom-link{
    font-size: var(--fs-smaller);
    text-align: center;
    color: var(--white-gray-color);
}

@media screen and (max-width: 550px){
    .notification__box{
        margin-right: 1rem;
    }
}