.appraisalTable__container{
    position: relative;
    margin-top: 1.5rem;
    padding: 1rem;
    background-color: var(--secondary-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px var(--secondary-color);
}

.appraisalTable__search{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.appraisalTable__search-title{
    font-size: var(--fs-normal);
    color: var(--white-gray-color);
    text-transform: uppercase;
}

.appraisalTable__search-box{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.appraisalTable__search-span{
    font-size: var(--fs-small);
    color: var(--white-gray-color);
    font-weight: 600;
}

.appraisalTable__search-input{
    padding: 0.4rem;
    font-size: var(--fs-smaller);
    width: 100%;
    color: var(--white-gray-color);
    background-color: transparent;
    border: 2px solid var(--bg-color);
    outline: none;
    border-radius: 5px;
    transition: all .3s ease-in-out;
}

.appraisalTable__search-input:focus{
    border: 2px solid var(--primary-color);
}

.appraisalTable__table{
    margin-top: 1rem;
    display: flex;
    align-items: center;
}

.appraisalTable__table-profile{
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.appraisalTable__table-img{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}

.appraisalTable__table-profile_list{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.appraisalTable__table-profile_name{
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
}

.appraisalTable__table-profile_email{
    font-size: var(--fs-smaller);
    color: var(--gray-color);
}

.appraisalTable__table-btn{
    /* height: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.appraisalTable__table-status{
    width: 80px;
    padding: 0.5rem;
    display: flex;
    align-items: start;
    justify-content: center;
    border-radius: 5px;
    color: var(--bg-color);
    font-size: var(--fs-smaller);
    font-weight: var(--fw-bold);
}

.appraisalTable__table-status.pending{
    background-color: var(--orange-color);
}
.appraisalTable__table-status.updated{
    background-color: var(--orange-color);
}


/* UPDATE APPRAISAL TABLE */

.updateAppraisal__form{
    margin-top: 1.5rem;
    background-color: var(--secondary-color);
    padding: 1rem;
    border-radius: 5px;
}

.updateAppraisal__user-info{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-bottom: 1px solid var(--bg-color);
    padding-bottom: 1rem;
}

.updateAppraisal__user-info__title{
    font-size: var(--fs-small);
    color: var(--white-gray-color);
    text-transform: uppercase;
    
}

.updateAppraisal__user-item{
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
    gap: 1rem;
}

.updateAppraisal__user-field{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.3rem;
}

.updateAppraisal__user-label{
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
    font-weight: 600;
}

.updateAppraisal__user-input{
    width: 100%;
    padding: 0.5rem;
    background-color: transparent;
    outline: none;
    border: 1px solid var(--bg-color);
    border-radius: 5px;
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
}

.updateAppraisal__content{
    position: relative;
    margin-top: 1rem;
}

.updateAppraisal__content-title,
.updateAppraisal__content-title{
    font-size: var(--fs-small);
    color: var(--white-gray-color);
    text-transform: uppercase;
    
}

.updateAppraisal__content-note{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.updateAppraisal__content-note_title{
    padding: 0.4rem;
    width: 100%;
    margin: 0.5rem 0;
    background-color: var(--primary-color);
    font-size: var(--fs-smaller);
    
    color: #fff;
    text-align: left;
}

.updateAppraisal__content-note_span{
    padding: 0.2rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
}

.updateAppraisal__content-note_span span{
    font-weight: 600;
}

.updateAppraisal__form-table{
    margin-top: 1rem;
}

.updateAppraisal__table-container{
    position: relative;
    /* min-width: 600px; */
    overflow-x: auto;
}

.updateAppraisal__table{
    position: relative;
    width: 100%;
    margin-top: 0.5rem;
    border-collapse: collapse;
}

.updateAppraisal__table .updateAppraisal__table__head{
    width: 100%;
}

.updateAppraisal__table__head tr{
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
    
}

.updateAppraisal__table__head tr th,
.updateAppraisal__table__body tr td{
    width: 100%;
    border: 1px solid var(--bg-color);
    text-align: start;
}

.updateAppraisal__table__body tr td{
    border-top: none !important;
}

.updateAppraisal__table__head tr th{
    padding: 0.5rem;
}

.updateAppraisal__table__head tr th:nth-child(1),
.updateAppraisal__flex-1{
    max-width: 50px;
}

.updateAppraisal__table__head tr th:nth-child(2),
.updateAppraisal__flex-2{
    max-width: 200px;
}

.updateAppraisal__table__head tr th:nth-child(4),
.updateAppraisal__table__head tr th:nth-child(5),
.updateAppraisal__table__head tr th:nth-child(6),
.updateAppraisal__flex-4,
.updateAppraisal__flex-5,
.updateAppraisal__flex-6{
    max-width: 90px;
}

.updateAppraisal__table__head,
.updateAppraisal__table__body{
    width: 100%;
}

.updateAppraisal__table-parent_tr,
.updateAppraisal__table-child_tr{
    display: flex;
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);

}

.updateAppraisal__table-child_tr{
    flex-direction: column;
    height: 100%;
}

.updateAppraisal__table-child_tr td{
    padding: 0.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid var(--bg-color);
}

.updateAppraisal__flex-1,
.updateAppraisal__flex-2{
    padding: 0.5rem;
}

.updateAppraisal__table-input{
    width: 100%;
    padding: 0.3rem;
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
    background-color: transparent;
    border: 1px solid var(--bg-color);
    border-radius: 5px;
    outline: none;  
}

.updateAppraisal__form-item{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.2rem;
}

.updateAppraisal__form-label{
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
    
    font-weight: 600;
}

.appraisal__form-others{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
}

.appraisal__form-others_item{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.4rem;
    border: 1px solid var(--bg-color);
    border-radius: 8px;
}

.appraisal__form-others-label{
    font-size: var(--fs-small);
    color: var(--primary-color);
    text-transform: uppercase;
}

.appraisal__form-others_desc{
    color: var(--white-gray-color);
    font-size: var(--fs-smaller);
}

.appraisal__form-item{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.2rem;
}

.appraisal__form-label{
    font-size: var(--fs-smaller);
    color: var(--white-gray-color);
    text-transform: uppercase;
}

.updateAppraisal__form-btn{
    align-self: flex-end;
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow {
    background-color: var(--secondary-color) !important;
    color: var(--white-gray-color) !important;
  }
  
  .css-1j4lznq-MuiDataGrid-root .MuiDataGrid-cell,
  .css-rtrcn9-MuiTablePagination-root,
  .css-zylse7-MuiButtonBase-root-MuiIconButton-root,
  .css-1g8djar-MuiDataGrid-root.MuiDataGrid-root--densityStandard .MuiDataGrid-cell{
    color: var(--white-gray-color) !important;
  }
  
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, 
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
    color: var(--primary-color) !important;
  }


@media screen and (max-width: 990px) {
    
    .updateAppraisal__table{
        display: block;
        min-width: 600px;
        overflow-x: auto;
    }
}

@media screen and (max-width: 768px) {
    .appraisalTable__search-box{
        width: 100%;
    }
    .appraisalTable__search-title{
        display: none;
    }
    
    .updateAppraisal__table__head tr th:nth-child(1),
    .flex-1{
        max-width: 30px;
    }

    .updateAppraisal__table__head tr th:nth-child(2),
    .flex-2{
        max-width: 150px;
    }

    .updateAppraisal__table__head tr th:nth-child(4),
    .flex-4{
        max-width: 100px;
    }
}
